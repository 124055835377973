import {useState, useEffect} from 'react';
import VideoEffectivenessView from "./VideoEffectivenessView";
import {useNavigate} from "react-router-dom";
import mixpanel from 'mixpanel-browser';

const VideoEffectiveness = () => {
    const navigate = useNavigate();
    useEffect(() => { 
        mixpanel.track('video_effectiveness_page')
       }, [])

    const handleSubmit = () => {
        navigate('/video-creation-challenges')
    }

    return (
        <VideoEffectivenessView handleSubmit={handleSubmit} />
    );
};

export default VideoEffectiveness;
