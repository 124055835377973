import { createSlice } from '@reduxjs/toolkit';

interface State {
  id: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  token: string;
}

const initialState: State = {
  id: '',
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  token: '',
};

const UserSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setId(state, action) {
      state.id = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload;
    },
    setFirstName(state, action) {
      state.firstName = action.payload;
    },
    setLastName(state, action) {
      state.lastName = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
  },
});

export const { actions } = UserSlice;

export default UserSlice.reducer;
