import AppDownloadButton from './AppDownloadButton';

function GooglePlayDownloadButton() {
  return <AppDownloadButton
    icon='google-play'
    title='GET IT ON'
    text='Google Play'
  />
}

export default GooglePlayDownloadButton;