import AppStoreDownloadButton from './AppStoreDownloadButton';
import GetTheAppButton from './GetTheAppButton';
import GooglePlayDownloadButton from './GooglePlayDownloadButton';

function GetAppMultiOsButton() {
  const isAndroid = window.navigator.userAgent.toLocaleLowerCase().includes('android');
  const isMacOs = window.navigator.userAgent.toLocaleLowerCase().includes('mac os');
  const isIPhone = window.navigator.userAgent.toLocaleLowerCase().includes('iphone');
  const isMacOsNotDesktop = isMacOs && !window.navigator.userAgent.toLocaleLowerCase().includes('macbook')
  return isAndroid
      ? <GooglePlayDownloadButton />
      : isIPhone
          ? <AppStoreDownloadButton />
          : <GetTheAppButton />
}

export default GetAppMultiOsButton;