import { IAuthResponse, IAuthenticateStrategy, IdentityProviders } from '../../../contracts/auth.contract';
import { EmailTakenException } from '../../../contracts/exceptions/auth/email-taken.exception';
import constants from '../../../utils/constants';
import { Signleton } from '../../../utils/decorators/singleton';

export interface IOAuthAuthenticate {
  code: string;
  provider: IdentityProviders;
}

@Signleton()
export class OAuthAuthenticationStrategy implements IAuthenticateStrategy {
  async authenticate(data: IOAuthAuthenticate): Promise<IAuthResponse> {
    const query = `mutation($input: AuthLoginWithOauthCodeInput!){
      authLoginWithOauthCode(input:$input) {
          token
          userNew
          success
          errors {
            field
            message
          }
          user {
            id
            email
          }
      }
  }`;

  const variables = {
    input: data,
  };

  const body = JSON.stringify({ query, variables });

  const { token, userNew: isNewUser, user } = await fetch(`${constants.WOIS_API_ORIGIN}/auth/`, {
      headers: { 'content-type': 'application/json' },
      body,
      method: 'POST',
  })
      .then(response => response.json())
      .then(({ data }) => data.authLoginWithOauthCode);
  return { token, isNewUser, user };
  }
}

export interface IEmailAuthentication {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

@Signleton()
export class EmailAuthenticationStrategy implements IAuthenticateStrategy {
  async authenticate(data: IEmailAuthentication): Promise<IAuthResponse> {
    const query = `mutation ($input: AuthRegisterInput!){
      authRegister(input: $input) {
        errors {
          field
          message
        }
        success
        token
        user {
          email
          id
        }
      }
    }`;

  const variables = {
      input: data,
  };

  const body = JSON.stringify({ query, variables });

  const { token, user, errors } = await fetch(`${constants.WOIS_API_ORIGIN}/auth/`, {
      headers: { 'content-type': 'application/json' },
      body,
      method: 'POST',
  })
      .then(response => response.json())
      .then(({ data }) => data.authRegister);

    const hasEmailTaken = !!(errors as any[])
      ?.find(error => error.message === 'has already been taken' && error.field === 'email');

    if (hasEmailTaken) throw new EmailTakenException;

    return { token, user, isNewUser: true };
  }
}

@Signleton()
export class MockEmailAuthenticationStrategy implements IAuthenticateStrategy {
  private readonly authResponse: IAuthResponse;

  constructor(authResponse: IAuthResponse) {
    this.authResponse = authResponse;
  }

  authenticate(): Promise<IAuthResponse> {
    return Promise.resolve(this.authResponse);
  }
}
