import {useNavigate} from "react-router-dom";
import './GoBack.scss';

const GoBack = () => {
  const navigate = useNavigate();
  const handlePress = () => {
      navigate(-1)
  };

  return (
      <button className='go-back' onClick={handlePress}>
        <img src="/images/icons/back-i.svg" alt="leaf"/>
      </button>
  );
};

export default GoBack;
