import oneLink from '../lib/one-link';

const oneLinkProperties = {
  "oneLinkURL": "https://wois.onelink.me/O4Yw",
  "afParameters": {
      "mediaSource": {
          "keys": [
              "utm_source"
          ],
          "defaultValue": "meta"
      },
      "campaign": {
          "keys": [
              "utm_campaign"
          ],
          "defaultValue": "not_found"
      },
      "adSet": {
          "keys": [
              "utm_content"
          ],
          "defaultValue": "not_found"
      },
      "ad": {
          "keys": [
              "utm_term"
          ],
          "defaultValue": "not_found"
      },
      "afSub1": {
          "keys": [
              "fbclid"
          ]
      },
      "afCustom": [
          {
              "paramKey": "af_ss_ui",
              "defaultValue": "true"
          }
      ]
  }
}

/** this function is dirty as result depends on query params too */
function generateOneLinkDownloadLink(): string {
  return oneLink.generateOneLinkURL(oneLinkProperties).clickURL;
}

const oneLinkClient = {
/** this function is dirty as result depends on query params too */
    generateOneLinkDownloadLink,
};

export default oneLinkClient;
