import Icon from '../../../components/UI/Icon/Icon';
import './Review.scss';

export interface IReviewer {
  avatarUrl: string;
  name: string;
  label: string;
}

export interface IReview {
  reviewer: IReviewer;
  comment: string;
  stars: number;
}

function Review(props: IReview) {
  return (
      <div className='review'>
        <div className="review__card">
          <h3 className='review__comment'>{props.comment}</h3>

          <br />

          <div className='review__card__footer'>
            <img
              className='review__card__footer__reviewer-avatar'
              src={props.reviewer.avatarUrl}
              alt={`${props.reviewer.name} avatar`}
            />

            <div className='review__card__footer__right'>
              <div className='review__card__footer__right__stars'>
                {new Array(props.stars).fill(0).map((_, i) => <Icon key={i} name='star' />)}
              </div>

              <h3 className='review__card__footer__right__reviewer-name'>{props.reviewer.name}</h3>

              <div className='review__card__footer__right__label'>{props.reviewer.label}</div>  
            </div>
          </div>
        </div>
      </div>
  );
}

export default Review;
