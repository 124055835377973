import React from 'react';
import './App.scss';
import {BrowserRouter} from "react-router-dom";
import {useRoutes} from "../../navigation/routes";
import mixpanel from 'mixpanel-browser';
import { initializeApp } from "firebase/app";
import oneLinkClient from '../../sdk-clients/oneLink';
import constants from '../../utils/constants';
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBGMAvPqYJl3SDsNwISA5HZhrDMoMj52vw",
  authDomain: "wois-5152e.firebaseapp.com",
  projectId: "wois-5152e",
  storageBucket: "wois-5152e.appspot.com",
  messagingSenderId: "513101477868",
  appId: "1:513101477868:web:95aa418caa07b5c62d32c9",
  measurementId: "G-0TE6GCL045"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

localStorage.setItem('download-link', oneLinkClient.generateOneLinkDownloadLink());

const App: React.FC = () => {
    const routes = useRoutes()

    mixpanel.init('a12b7d92b0ebd870483fe133de6dcbd3',
     {debug: constants.MIXPANEL_IS_TEST,
    track_pageview: true,
    persistence: 'localStorage'});
     
    return (
        <BrowserRouter>
            {routes}
        </BrowserRouter>
    );
}

export default App;
