import './VideoEffectiveness.scss';
import GoBack from "../../components/UI/Buttons/GoBack/GoBack";
import BarChart from '../../components/UI/Charts/Bar/Bar';

interface GoalsViewProps {
    handleSubmit: () => void;
}

const GoalsView = (props: GoalsViewProps) => {
    return (
        <div className='page video-effectiveness-page'>
            <header>
                <GoBack/>
                <a href="/"><img className='logo' src="./images/logo.svg" alt="Logo"/></a>
                <img src="./images/step/step-6.svg" alt="leaf"/>
            </header>
            <main className='video-effectiveness-page__main'>
                <h2 className='video-effectiveness-page__main__title'>
                    Your camera is
                    <br />
                    your best friend
                </h2>

                <div className='video-effectiveness-page__main__card'>
                    <div className='video-effectiveness-page__main__card__charts'>
                        <div className='video-effectiveness-page__main__card__charts__retention'>
                            <p>RETENTION</p>
                        </div>

                        <div className='video-effectiveness-page__main__card__charts__video'>
                            <BarChart history={[0, 95]}/>
                            <p className='bar-description'>VIDEO</p>
                        </div>

                        <div className='video-effectiveness-page__main__card__charts__text'>
                            <BarChart history={[0, 10]}/>
                            <p className='bar-description'>TEXT</p>
                        </div>
                        <img className='people' src="/images/peoples/video-effectivness.png" alt="people"/>
                        
                        <hr className='video-effectiveness-page__main__card__charts__hr' />
                    </div>

                    <div className='video-effectiveness-page__main__card__texts'>
                        <h3 className='video-effectiveness-page__main__card__texts__title'>
                            Videos convey your emotions and personality more authentically than text-based content.
                        </h3>

                        <div className="video-effectiveness-page__main__card__texts__gitnux">
                            <img src="/images/githux-logo.svg" alt="Gitnux" />
                            <h3 className='video-effectiveness-page__main__card__texts__gitnux__researches'>
                                <span className='text-white'>Gitnux.org’s</span> research shows that viewers retain
                                95% of a video's message compared to 10%
                                when reading the text.
                            </h3>
                        </div>
                    </div>
                </div>
            </main>
            <footer>
                <button onClick={props.handleSubmit}>Next</button>
            </footer>

        </div>
    );
};

export default GoalsView;
