import {createSlice} from '@reduxjs/toolkit';

interface State {
  data:  {
    darkMode:boolean;
  };
  isLoading: boolean;
  error: string | null;
}

const initialState: State = {
  data: {
    darkMode: false
  },
  isLoading: false,
  error: null,
};

const AppSlice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    setDarkMode: (state, action) => {
      state.data.darkMode = action.payload
    },
  },
});

export const {setDarkMode} = AppSlice.actions;

export default AppSlice.reducer;
