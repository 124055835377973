import { IConstructor } from '../../contracts/util-types/contructor';

export function Signleton() {
  return function<T extends IConstructor>(target: T) {
    let instance: InstanceType<T>;

    return class extends target {
      constructor(...args: any[]) {
        if (instance) return instance;

        super(...args);
        instance = this as InstanceType<T>;
      }
    } as T;
  } 
}
