import PaywallView from './PaywallView';
import mixpanel from 'mixpanel-browser';
import { useEffect} from 'react';

const Paywall = () => {
    useEffect(() => { 
        mixpanel.track('paywall_page')
       }, [])

    return (
        <PaywallView />
    );
};

export default Paywall;
