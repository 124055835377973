import AppDownloadButton from './AppDownloadButton';

function AppStoreDownloadButton() {
  return <AppDownloadButton
    icon='apple-corp'
    title='Download on the'
    text='App Store'
  />
}

export default AppStoreDownloadButton;