import './Cross.scss';
import { MouseEventHandler } from 'react';

export interface ICross {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const Cross = (props: ICross) => {
  return (
      <button className='cross' onClick={props.onClick}>
        <img src="./images/icons/cross.svg" alt="cross"/>
      </button>
  );
};

export default Cross;
