import { useEffect } from 'react';
import './Snakbar.scss';

export interface ISnakbar {
  isActive: boolean;
  setIsActive(isActive: boolean): void;
  text: string;
  /**
   * miliseconds of how long snakbar will be appeared
   * default is 3000
   */
  delay?: number;
}

function Snakbar(props: ISnakbar) {
  const delay = props.delay ?? 3000;
  let timeout: string | number | NodeJS.Timeout | undefined;

  useEffect(() => {
    if (props.isActive) {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        props.setIsActive(false);
      },
      delay);
    }
  }, [props.isActive]);

  return (
    <div className={['snakbar', props.isActive ? 'active': ''].join(' ')}>

      <img
        onClick={() => props.setIsActive(false)}
        src="/images/icons/red-rounded-cross.svg"
        alt="cross"
      />

      <div className="snakbar__text">
        {props.text}
      </div>
    </div>
  );
}

export default Snakbar;
