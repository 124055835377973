import { useEffect } from 'react';
import './AuthLoading.scss';
import { useNavigate } from 'react-router-dom';
import { Player, PlayerEvent } from '@lottiefiles/react-lottie-player';
import AccessDenied from './AccessDenied/AccessDenied';
import GoBack from '../../../components/UI/Buttons/GoBack/GoBack';
import EventEmitter from '../../../utils/EventEmitter';
import { store } from '../../../redux/store/Store';
import { actions as UserSliceActions } from '../../../redux/UserSlice';
import { AuthErrors, IAuthResponse, IAuthenticateStrategy } from '../../../contracts/auth.contract';
import authLoadingLottyAnimation from './AuthLoadingLottyAnimation';

export interface IAuthLoading<T extends IAuthenticateStrategy> {
  authStrategy: T;
  authData: Parameters<T['authenticate']>[0];
  error?: AuthErrors;
}

function AuthLoading<T extends IAuthenticateStrategy>(props: IAuthLoading<T>) {
  const navigate = useNavigate();

  const PLAYER_COMPLETE_EVENT = 'PLAYER_COMPLETE_EVENT';

  const eventEmitter = new EventEmitter;

  async function onAuthenticate(response: IAuthResponse) {
    localStorage.setItem('token', response.token);
    localStorage.setItem('email', response.user.email);
    localStorage.setItem('userId', response.user.id);

    store.dispatch(UserSliceActions.setToken(response.token));
    store.dispatch(UserSliceActions.setEmail(response.user.email));
    store.dispatch(UserSliceActions.setId(response.user.id));

    eventEmitter.on(
      PLAYER_COMPLETE_EVENT,
      () => navigate(response.isNewUser ? '/paywall' : '/welcome-aboard')
    );
  }

  function onPlayerEvent(event: PlayerEvent) {
    if (event === 'complete') eventEmitter.emit(PLAYER_COMPLETE_EVENT);
  }

  useEffect(() => {
      if (props.error === undefined) {
        props.authStrategy.authenticate(props.authData)
          .then(onAuthenticate);
      }
    },
    []
  );

  return props.error === AuthErrors.ACCESS_DENIED ?
    <AccessDenied /> :
    <div className='auth-loading page'>
      <header>
          <GoBack />
          <a href="/"><img className='logo' src="/images/logo.svg" alt="Logo"/></a>
          <img src="/images/step/step-9.svg"/>
      </header>

      <main className='auth-loading__main'>
          <h3>Let's start</h3>

          <h2>
            We are preparing
            <br />
            your brand's strategy
          </h2>

          <br />
          <br />
          <br />

          <div className='auth-loading__main__before-after'>
            <div>
              <img className='auth-loading__main__before-after__emoji' src='/images/emoji/loudly-crying-face.png' />
              &nbsp;
              Before
            </div>

            <div>
              After
              &nbsp;
              <img className='auth-loading__main__before-after__emoji' src='/images/emoji/star-struck.png' />
            </div>
          </div>

          <br />
          
          <Player
            autoplay
            keepLastFrame
            src={authLoadingLottyAnimation}
            onEvent={onPlayerEvent}
          />
      </main>
    </div>
}

export default AuthLoading;
