import { useEffect } from 'react';
import './AccessDenied.scss';
import { useNavigate } from 'react-router-dom';

function AccessDenied() {
  const navigate = useNavigate();

  useEffect(() => void setTimeout(() => navigate('/download-app'), 5000), []);

  return (
    <div className="auth-loading-access-denied">
      <div>
        <h2>Access denied</h2>
        <br />
        <h3>You've cancelled authentication process</h3>
        <h3>You will be replaced to the app download page soon...</h3>
      </div>
    </div>
  );
}

export default AccessDenied;
