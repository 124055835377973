import React from 'react';
import './Welcome.scss';

const WelcomeView = ({handleSubmit}: {handleSubmit: () => void}) => {
    return (
        <div className='welcome-page page'>
            <div className='welcome-page__top-part'>
                <a href="/"><img className='logo'  src="./images/logo.svg" alt="Logo"/></a>
                <img className='welcome-page__top-part__bg' src="/images/backgrounds/bg.png" />
            </div>
            <div className='bottom-part welcome-page__bottom-part'>
                <div className='text-container'>
                    <h1>Building a personal brand <span>is easier than you think!</span></h1>
                    <h2>We’ll prove it to you.</h2>
                </div>
                <div className='button-container'>
                    <button onClick={handleSubmit}>Start your journey</button>
                    <div className='awards'>
                        <div className='awards__top'>
                            <img src="./images/awards/award-1.svg" alt="leaf"/>
                            <img src="./images/awards/award-2.svg" alt="leaf"/>
                        </div>
                        <div className='awards__bottom'>
                            <img src="./images/awards/award-3.svg" alt="leaf"/>
                            <img src="./images/awards/award-4.svg" alt="leaf"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomeView;
