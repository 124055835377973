class CustomeEvent extends Event {
  message: any;

  constructor(eventType: string, message: any) {
    super(eventType);

    this.message = message;
  }
}

export class EventEmitter {
  private eventSource = document.createElement('div');

  on(event: string, cb: (message?: any) => void) {
    this.eventSource.addEventListener(event, event => cb((event as CustomeEvent).message));
  }

  emit(event: string, message?: any) {
    const eventInstance = new CustomeEvent(event, message);

    this.eventSource.dispatchEvent(eventInstance);
  }
}

export default EventEmitter;
