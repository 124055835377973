import {useState, useEffect} from 'react';
import SocialPresenceView from "./SocialPresenceView";
import {useNavigate} from "react-router-dom";
import {ListWithSelectsButtonData} from "../../../components/UI/ListWithSelect/ListWithSelect";
import mixpanel from 'mixpanel-browser';

const SocialPresence = () => {
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState< string>('');
    useEffect(() => { 
        mixpanel.track('social_presence_page')
       }, [])


    const selectData: ListWithSelectsButtonData[] = [
        {
            key: '1',
            title: 'Active',
            subtitle: 'I regularly post on multiple platforms',
            emoji: '🚀',
            emojiStyle: {marginRight: 10}
        },
        {
            key: '2',
            title: 'Rarely',
            subtitle: 'I have social accounts but I barely use them',
            emoji: '🕸️',
            emojiStyle: {marginRight: 10}
        },
        {
            key: '3',
            title: 'Not active',
            subtitle: 'I\'m starting from scratch',
            emoji: '🌱',
            emojiStyle: {marginRight: 10}
        },
    ];


    const handleSelect = (value: string | any) => {
        setSelectedItem( value)
    };

    const handleSubmit = () => {
        mixpanel.track('social_presence_selected', {
            'version': 1.0,
            'title': selectData.find(data => data.key === selectedItem)
          })
        navigate('/time-investment')
    }

    return (
        <SocialPresenceView DATA={selectData} handleSelect={handleSelect} handleSubmit={handleSubmit} selectedItem={selectedItem}/>
    );
};

export default SocialPresence;
