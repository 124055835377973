import { useState, useEffect } from 'react';
import './Bar.scss';

export interface IBarChart {
  history: number[];
}

function BarChart(props: IBarChart) {
  const [progress, setProgress] = useState(props.history[0] ?? 0);

  const progressIterator = {
    async *[Symbol.asyncIterator]() {
      const start = props.history.at(0)!;
      const end = props.history.at(-1)!;
      const ms = 500 / end;

      for (let i = start; i <= end; i++) {
        yield await new Promise(resolve => setTimeout(() => resolve(() => setProgress(i)), ms));
      }
    }
  }

  useEffect(() => {
    (async () => {
      for await (const item of progressIterator) {
        (item as any)();
      }
    })()
  }, [])

  return (
    <div className='bar-chart'>
      <p className='bar-chart__digit-indicator'>
        {`${progress}%`}
      </p>
      <div
        className='bar-chart__body'
        style={
          {
            height: `${progress}%`
          }
        }
      ></div>
    </div>
  );
}

export default BarChart;
