import './DownloadApp.scss';
import GoBack from "../../components/UI/Buttons/GoBack/GoBack";
import { useNavigate } from 'react-router-dom';
import GetAppMultiOsButton from '../../components/UI/Buttons/AppDownloadButton/GetAppMultiOsButton';
import mixpanel from 'mixpanel-browser';
import { useEffect} from 'react';

const DownloadApp = () => {
    useEffect(() => { 
        mixpanel.track('download_page')
       }, [])

    const navigate = useNavigate();

    return (
        <div className='page download-app-page fade-in'>
            <header className='download-app-page__header'>
                <div></div>
                <a href="/"><img className='logo' src="./images/logo.svg" alt="Logo"/></a>
                <div></div>
            </header>
            <main className='download-app-page__main'>
                <div className='download-app-page__main__text'>
                    <h2 className='text-align-center'>
                        Download the App!
                    </h2>
                    <h3 className='text-align-center'>
                        Try the free version of Wois to get
                        <br/>
                        a taste of what you’re missing out on
                    </h3>
                </div>

                <div className='download-app-page__main__app-store-download-btn'>
                    <GetAppMultiOsButton />
                </div>
            </main>

            <footer className='download-app-page__footer'>
                <button onClick={() => navigate('/paywall')}>Give me the offer</button>
            </footer>
        </div>
    );
};

export default DownloadApp;
