import React, { useState } from 'react';
import './Interests.scss';
import GoBack from "../../../components/UI/Buttons/GoBack/GoBack";
import ListWithSelects, {ListWithSelectsButtonData} from "../../../components/UI/ListWithSelect/ListWithSelect";
import Snakbar from '../../../components/UI/Snakbar/Snakbar';

interface Props {
    handleMultiSelect: (value: string) => void,
    selectedItem: string[] | [],
    DATA: ListWithSelectsButtonData[],
    handleSubmit: () => void,
}

const InterestsView = (props: Props) => {

    const handleCountSelectedItems = () => {
      if (props.selectedItem.length === 0) {
          return  'Select 3 more'
      }
        if (props.selectedItem.length < 2) {
            return  'Select 2 more'
        }
        if (props.selectedItem.length < 3) {
            return  'Select 1 more'
        }
        if (props.selectedItem.length >= 3) {
            return  'Next'
        }
    }

    const [isSnakbarActive, setIsSnakbarActive] = useState(false);

    function onSubmit() {
        if (props.selectedItem.length >= 3) props.handleSubmit();
        else setIsSnakbarActive(true);
    }

    function onSelectMulti(value: string) {
        if (isSnakbarActive && props.selectedItem.length < 3) setIsSnakbarActive(false);

        props.handleMultiSelect(value);
    }

    return (
        <div className='page interests-page'>
            <Snakbar isActive={isSnakbarActive} setIsActive={setIsSnakbarActive} text={`Select ${3 - props.selectedItem.length} more`} />

            <header style={{
                backgroundImage: 'url("./images/backgrounds/red-bg-top.svg")',
                backgroundSize: '80%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}>
                <GoBack/>
                <a href="/"><img className='logo' src="./images/logo.svg" alt="Logo"/></a>
                <img src="./images/step/step-2.svg" alt="leaf"/>
            </header>
            <main>
                <h3>YOUR INTERESTS</h3>
                <h2>What are you experienced in or <br/> passionate about?</h2>
                <h4 className='text-MD16 text-gray'>(Select at least 3)</h4>

                <br />

                <ListWithSelects
                    callback={onSelectMulti}
                    selectedItem={props.selectedItem}
                    data={props.DATA}
                    multiSelect={true}
                />
            </main>
            <footer>
                <button onClick={onSubmit}>{handleCountSelectedItems()}</button>
            </footer>

        </div>
    );
};

export default InterestsView;
