import React, { useEffect } from 'react';
import WelcomeView from "./WelcomeView";
import {useNavigate} from "react-router-dom";
import mixpanel from 'mixpanel-browser';

const Welcome = () => {
    const navigate = useNavigate();

    useEffect(() => { 
      mixpanel.track('welcome_page')
     }, [])


    const handleSubmit = () => {
      
      navigate('goals')
    }

    return (
        <WelcomeView handleSubmit={handleSubmit}/>
    );
};

export default Welcome;
