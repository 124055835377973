import React from 'react';
import './ChoiceButton.scss';

interface ChoiceButtonProps {
    callback?: () => void;
    isSelected: boolean | undefined;
    title: string;
    subtitle?: string;
    emoji?: string;
    titleStyle?: React.CSSProperties;
    subtitleStyle?: React.CSSProperties;
    emojiStyle?: React.CSSProperties;
    buttonStyle? :React.CSSProperties;
}

const ChoiceButton: React.FC<ChoiceButtonProps> = (props) => {

    return (
        <div style={props.buttonStyle} className={props.isSelected ? 'choice-button, choice-button-selected' : 'choice-button'} onClick={props.callback}>
            <div className='choice-button__container'>
                {props.emoji && <p className='choice-button__emoji' style={props.emojiStyle}>{props.emoji}</p>}
                <div>
                    <p className='choice-button__title' style={props.titleStyle}>{props.title}</p>
                    {props.subtitle && <p className='choice-button__subtitle' style={props.subtitleStyle}>{props.subtitle}</p>}
                </div>
            </div>
            <img className='choice-button__checkbox' src={props.isSelected ? "./images/icons/checkbox-cheked.svg" : "./images/icons/checkbox-empty.svg"}
                 alt="checkbox"/>
        </div>

    );
};

export default ChoiceButton;
