import './VideoCreationChallenges.scss';
import GoBack from "../../../components/UI/Buttons/GoBack/GoBack";
import ListWithSelects, {ListWithSelectsButtonData} from "../../../components/UI/ListWithSelect/ListWithSelect";
import { useState } from 'react';
import Snakbar from '../../../components/UI/Snakbar/Snakbar';

interface Props {
    handleSelect: (value: string) => void,
    selectedItem: string[] | [],
    DATA: ListWithSelectsButtonData[],
    handleSubmit: () => void,
}

const VideoCreationChallengesView = (props: Props) => {
    const [isSnakbarActive, setIsSnakbarActive] = useState(false);

    function onSubmit() {
        if (props.selectedItem.length) props.handleSubmit();
        else setIsSnakbarActive(true);
    }

    function onSelect(value: string) {
        if (isSnakbarActive) setIsSnakbarActive(false);

        props.handleSelect(value);
    }

    return (
        <div className='page video-creation-challenges-page'>
            <Snakbar isActive={isSnakbarActive} setIsActive={setIsSnakbarActive} text='Make your selection' />

            <header>
                <GoBack/>
                <a href="/"><img className='logo' src="./images/logo.svg" alt="Logo"/></a>
                <img src="./images/step/step-7.svg" alt="leaf"/>
            </header>
            <main>
                <h3>
                    Challenges of Video Content Creation
                </h3>
                <h2 className='text-align-center'>
                    Please select the challenges that resonate with you
                </h2>
                <h4 className='text-MD16 text-gray'>(Select all that apply)</h4>

                <br />

                <ListWithSelects
                    callback={onSelect}
                    selectedItem={props.selectedItem}
                    data={props.DATA}
                    multiSelect={true}
                />
            </main>
            <footer>
                <button onClick={onSubmit}>Next</button>
            </footer>

        </div>
    );
};

export default VideoCreationChallengesView;
