import {Route, Routes} from "react-router-dom";
import Auth from "../pages/Auth/Auth";
import Paywall from "../pages/Paywall/Paywall";
import Welcome from "../pages/Welcome/Welcome";
import Goals from "../pages/Quiz/Goals/Goals";
import Interests from "../pages/Quiz/Interests/Interests";
import SocialPresence from "../pages/Quiz/SocialPresence/SocialPresence";
import TimeInvestment from "../pages/Quiz/TimeInvestment/TimeInvestment";
import InFrontCameraComfort from "../pages/Quiz/InFrontCamera/InFrontCamera";
import VideoEffectiveness from "../pages/VideoEffectiveness/VideoEffectiveness";
import VideoCreationChallenges from "../pages/Quiz/VideoCreationChallenges/VideoCreationChallenges";
import PersonalBrandStep1 from "../pages/PersonalBrandStep1/PersonalBrandStep1";
import WelcomeAboard from "../pages/WelcomeAboard/WelcomeAboard";
import DownloadApp from "../pages/DownloadApp/DownloadApp";
import AuthLoadingGoogle from "../pages/Auth/AuthLoadingGoogle/AuthLoadingGoogle";
import AuthLoadingApple from "../pages/Auth/AuthLoadingApple/AuthLoadingApple";
import PlainAuthStep1 from "../pages/Auth/PlainSignUp/Step1/Step1";
import PlainAuthStep2 from "../pages/Auth/PlainSignUp/Step2/Step2";
import AuthLoadingPlain from "../pages/Auth/AuthLoadingPlain/AuthLoadingPlain";
import PaymentCallback from "../pages/PaymentCallback/PaymentCallback";

export const useRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Welcome/>}/>
            <Route path="/goals" element={<Goals/>}/>
            <Route path="/interests" element={<Interests/>}/>
            <Route path="/paywall" element={<Paywall/>}/>
            <Route path="/payment-callback" element={<PaymentCallback/>}/>
            <Route path="/social-presence" element={<SocialPresence/>}/>
            <Route path="/time-investment" element={<TimeInvestment/>}/>
            <Route path="/in-front-camera-comfort" element={<InFrontCameraComfort/>}/>
            <Route path="/video-effectiveness" element={<VideoEffectiveness/>}/>
            <Route path="/video-creation-challenges" element={<VideoCreationChallenges/>}/>
            <Route path="/personal-brand-step1" element={<PersonalBrandStep1/>}/>
            <Route path="/welcome-aboard" element={<WelcomeAboard/>}/>
            <Route path="/download-app" element={<DownloadApp/>}/>

            <Route path="/auth" element={<Auth/>}/>
            <Route path="/auth/plain/step-1" element={<PlainAuthStep1/>}/>
            <Route path="/auth/plain/step-2" element={<PlainAuthStep2/>}/>

            <Route path="/auth/plain/callback" element={<AuthLoadingPlain />} />
            <Route path="/auth/google/callback" element={<AuthLoadingGoogle />}/>
            <Route path="/auth/apple/callback" element={<AuthLoadingApple />}/>
        </Routes>
    )
}
