export enum IdentityProviders {
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
}

export enum AuthErrors {
  ACCESS_DENIED = 'ACCESS_DENIED',
  EMAIL_TAKEN = 'EMAIL_TAKEN',
}

export interface IAuthResponse {
  token: string;
  isNewUser: boolean;
  user: {
    id: string;
    email: string;
  }
};

export interface IAuthenticateStrategy {
  authenticate(data: any): Promise<IAuthResponse>;
}
