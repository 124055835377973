import React, {useState, useEffect} from 'react';
import InterestsView from "./InterestsView";
import {useNavigate} from "react-router-dom";
import {ListWithSelectsButtonData} from "../../../components/UI/ListWithSelect/ListWithSelect";
import mixpanel from 'mixpanel-browser';

const Interests = () => {
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState<string[] | []>([]);
    useEffect(() => { 
        mixpanel.track('interests_page')
       }, [])

    const selectData:ListWithSelectsButtonData[] = [
        {
            key: '1',
            title: 'Professional Development',
            emoji: '💼',
            emojiStyle: {fontSize: 16, marginRight: 4}
        },
        {
            key: '2',
            title: `Startups`,
            emoji: '🚀',
            emojiStyle: {fontSize: 16, marginRight: 4}
        },
        {
            key: '3',
            title: 'Leadership Development',
            emoji: '🌟',
            emojiStyle: {fontSize: 16, marginRight: 4}
        },
        {
            key: '4',
            title: 'Team Building',
            emoji: '🤝',
            emojiStyle: {fontSize: 16, marginRight: 4}
        },
        {
            key: '5',
            title: 'Business Strategy',
            emoji: '🎯',
            emojiStyle: {fontSize: 16, marginRight: 4}
        },
        {
            key: '6',
            title: `Venture Capital`,
            emoji: '🚀',
            emojiStyle: {fontSize: 16, marginRight: 4}
        },
        {
            key: '7',
            title: `Investment Strategies`,
            emoji: '📊',
            emojiStyle: {fontSize: 16, marginRight: 4}
        },
        {
            key: '8',
            title: `Mindset`,
            emoji: '🌟',
            emojiStyle: {fontSize: 16, marginRight: 4}
        },
        {
            key: '9',
            title: `Goal Setting`,
            emoji: '🎯',
            emojiStyle: {fontSize: 16, marginRight: 4}
        },
        {
            key: '10',
            title: `Productivity`,
            emoji: '✅',
            emojiStyle: {fontSize: 16, marginRight: 4}
        },
        {
            key: '11',
            title: `Emotional Intelligence`,
            emoji: '🧠',
            emojiStyle: {fontSize: 16, marginRight: 4}
        },
        {
            key: '12',
            title: `Self Development`,
            emoji: '📈',
            emojiStyle: {fontSize: 16, marginRight: 4}
        },
    ];

    const handleMultiSelect = (value: string | any) => {

        // @ts-ignore
        const isSelected = selectedItem.includes(value);

        if (isSelected) {
            setSelectedItem(selectedItem.filter((item: any) => item !== value));
        } else {
            setSelectedItem([...selectedItem, value as string]);
        }
    };

    const handleSubmit = () => {
        const selectedItems = selectData.filter(data => selectedItem.some((si) => si === data.key));

        for(const item of selectedItems) {
            mixpanel.track('interests_selected', {
                'version': 1.0,
                'title': item.title
              })
        }

        navigate('/social-presence')
    }

    return (
        <InterestsView DATA={selectData} handleMultiSelect={handleMultiSelect} handleSubmit={handleSubmit} selectedItem={selectedItem}/>
    );
};

export default Interests;
