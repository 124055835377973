import './SpecialOffer.scss'
import Timer from '../Timer/Timer';
import { useState } from 'react';
import PlainLoader from '../Loaders/PlainLoader/PlainLoader';

export interface ISpecialOfferAd {
  /** delay in seconds */
  title: string;
  delay: number;
  price: number;
  discount: number;
  currency: '€' | '$';
  isLoading?: boolean;

  onClaim: () => void;
}

function SpecialOfferAd(props: ISpecialOfferAd) {
  const priceWithDiscount = props.price * (props.discount / 100);

  return (
      <div className="special-offer">
        <div className='special-offer__timer'>
          <Timer delay={props.delay * 1000} />
        </div>

        <br />

        <div className='special-offer__title'>{props.title}</div>
        
        <br />

        <div className='special-offer__pricing'>
          <div className='special-offer__pricing__top'>
            <div>
              <h2>{props.currency}{priceWithDiscount}</h2>
              <br />
              <span>first month</span>
            </div>

            <h2 className="special-offer__pricing__top__discount">
              - {props.discount}%
            </h2>
          </div>

          <h3 className='special-offer__pricing__bottom'>
            then {props.price}{props.currency}/month
          </h3>
        </div>

        <br />

        <button
          className='special-offer__claim-offer'
          onClick={props.onClaim}
          disabled={props.isLoading}
        >
          { props.isLoading
            ? <div className='special-offer__claim-offer__loader'>
              <PlainLoader size='1.5rem' />
            </div> 
            : 'Claim my offer'
          }
        </button>

        <div className='special-offer__pay-as-go'>
          Pay as you go, cancel any time.
        </div>
      </div>
  );
}

export default SpecialOfferAd;
