import { AuthErrors, IdentityProviders } from '../../../contracts/auth.contract';
import AuthLoading from '../AuthLoading/AuthLoading';
import { OAuthAuthenticationStrategy } from '../AuthLoading/AuthStrategies';
import mixpanel from 'mixpanel-browser';
import { useEffect} from 'react';


/**
 * this page used for both redirect and popup sign in
 */
function AuthLoadingGoogle() {
  const query = new URLSearchParams(window.location.search);
  const code = query.get('code')!;
  const error = query.get('error') === 'access_denied' ? AuthErrors.ACCESS_DENIED: undefined;

  const isOpenedInPopupMode = window.opener !== null; 
  if (isOpenedInPopupMode) {
    const isSuccess = code !== null;

    window.opener.postMessage(isSuccess ? { code } : { error: query.get('error') }, '*');
  }

  useEffect(() => { 
    mixpanel.track('auth_loading_google_page')
  }, [])
 
  return <AuthLoading
    error={error}
    authStrategy={new OAuthAuthenticationStrategy}
    authData={
      { code, provider: IdentityProviders.GOOGLE }
    }
  />;
}

export default AuthLoadingGoogle;
