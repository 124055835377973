import React, {useState, useEffect} from 'react';
import GoalsView from "./GoalsView";
import {useNavigate} from "react-router-dom";
import {ListWithSelectsButtonData} from "../../../components/UI/ListWithSelect/ListWithSelect";
import mixpanel from 'mixpanel-browser';

const Goals = () => {
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState<string[]>([]);
    useEffect(() => { 
        mixpanel.track('goals_page')
       }, [])
  
  
    const selectData:ListWithSelectsButtonData[] = [
        {
            key: '1',
            title: `Establish expertise`,
            emoji: '💼',
            emojiStyle: { lineHeight: '.5rem'}
        },
        {
            key: '2',
            title: `Attract clients`,
            emoji: '📈',
            emojiStyle: { lineHeight: '.5rem'}
        },
        {
            key: '3',
            title: `Expand network`,
            emoji: '🤝',
            emojiStyle: { lineHeight: '.5rem'}
        },
        {
            key: '4',
            title: `Find jobs`,
            emoji: '🔍',
            emojiStyle: { lineHeight: '.5rem'}
        },
        {
            key: '5',
            title: `Inspire others`,
            emoji: '✨',
            emojiStyle: { lineHeight: '.5rem'}
        },
    ];

    const handleSelect = (value: string | any) => {
        const isSelected = selectedItem.includes(value);

        if (isSelected) {
            setSelectedItem(selectedItem.filter((item: any) => item !== value));
        } else {
            setSelectedItem([...selectedItem, value as string]);
        }
    };

    const handleSubmit = () => {

        const selectedItems = selectData.filter(data => selectedItem.some((si) => si === data.key));

        for(const item of selectedItems) {
            mixpanel.track('goal_selected', {
                'version': 1.0,
                'title': item.title
              })
        }
        navigate('/interests')
    }

    return (
        <GoalsView DATA={selectData} handleSelect={handleSelect} handleSubmit={handleSubmit} selectedItem={selectedItem}/>
    );
};

export default Goals;
