import { useEffect, useMemo, useRef, useState } from 'react';
import './TextField.scss';

export interface IRule {
  pattern: RegExp;
  error: string;
}

export interface ITextField {
  type?: 'text' | 'password';
  label?: string;
  value: string;
  setValue: (value: string) => void;

  rules?: IRule[];
}

function TextField(props: ITextField) {
  function onInput(event: React.FormEvent<HTMLInputElement>) {
    props.setValue((event.target as any).value);
  }

  useEffect(() => setIsLabelUpped(!!props.value), [props.value]);

  function onFocus() {
    setIsLabelUpped(true);
  }

  function onBlur() {
    if (props.value.length === 0) setIsLabelUpped(false);
    setHasBlured(true);
  }

  function focus() {
    inputRef.current?.focus();
  }

  const inputRef = useRef<HTMLInputElement>(null);
  const [isLabelUpped, setIsLabelUpped] = useState(false);
  const [hasBlured, setHasBlured] = useState(false);
  const [shouldShowPassword, setShouldShowPassword] = useState(false);

  const validationError = useMemo(
    () => props.rules?.find(rule => !rule.pattern.test(props.value)) ?? null,
    [props.value, props.rules]
  );

  const isInvalidValid = useMemo(
    () => hasBlured ? validationError !== null : false,
    [validationError, hasBlured]
  );

  return (
    <div className='ui-text-field'>
      <div
        className={
          [
            'ui-text-field__inner',
            isInvalidValid ? 'invalid' : '',
          ].join(' ')
        }
      >
        <label
          onClick={focus}
          className={
            [
              'ui-text-field__inner__label',
              isLabelUpped ? 'up' : '',
            ].join(' ')
          }
        >
          {props.label}
        </label>

        <input
          ref={inputRef}
          className='ui-text-field__inner__input'
          type={shouldShowPassword ? 'text' : props.type}
          value={props.value}
          onInput={onInput}
          onFocus={onFocus}
          onBlur={onBlur}
        />

        {
          props.type === 'password'
            ? <img
              src={`/images/icons/${shouldShowPassword ? 'eye-closed.svg' : 'eye-open.svg'}`}
              className='ui-text-field__inner__eye'
              onClick={() => setShouldShowPassword(!shouldShowPassword)}
            />
            : <></>
        }
      </div>

      {hasBlured && validationError !== null ?
          <div className='ui-text-field__error fade-in'>
            {validationError.error}
          </div>:
          <></>
        }
    </div>
  );
}

export default TextField;
