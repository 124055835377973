export interface IIcon {
  name: string;
}

function Icon(props: IIcon) {
  return (<img
    src={`./images/icons/${props.name}.svg`}
    alt={props.name}
  />);
}

export default Icon;
