import {useState, useEffect} from 'react';
import VideoCreationChallengesView from "./VideoCreationChallengesView";
import {useNavigate} from "react-router-dom";
import {ListWithSelectsButtonData} from "../../../components/UI/ListWithSelect/ListWithSelect";
import mixpanel from 'mixpanel-browser';

export interface ISelectSocialPresence {
    key: string;
    textProps: string;
    textProps2: string;
}
const VideoCreationChallenges = () => {
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState<string[] | []>([]);
    useEffect(() => { 
        mixpanel.track('video_challenge_page')
       }, [])

    const selectData: ListWithSelectsButtonData[] = [
        {
            key: '1',
            title: 'Time-consuming',
            subtitle: 'Planning, recording and editing is too time-consuming',
            emoji: '🏃‍',
            emojiStyle: {marginRight: 10},
            subtitleStyle: {fontSize: 12, letterSpacing: -0.28, lineHeight: '133%'}
        },
        {
            key: '2',
            title: 'No equipment',
            subtitle: 'No access to professional recording and editing equipment',
            emoji: '🎥',
            emojiStyle: {marginRight: 10},
            subtitleStyle: {fontSize: 12, letterSpacing: -0.28, lineHeight: '133%'}
        },
        {
            key: '3',
            title: 'Facing creative blocks',
            subtitle: 'Consistently coming up with and posting up engaging content is difficult',
            emoji: '😤',
            emojiStyle: {marginRight: 10},
            subtitleStyle: {fontSize: 12, letterSpacing: -0.28, lineHeight: '133%'}
        },
        {
            key: '4',
            title: 'Shy in front of camera',
            subtitle: 'Appearing in front of the camera is uncomfortable and affects the quality of my content',
            emoji: '😬',
            emojiStyle: {marginRight: 10},
            subtitleStyle: {fontSize: 12, letterSpacing: -0.28, lineHeight: '133%'}
        },
    ];

    const handleMultiSelect = (value: string | any) => {

        // @ts-ignore
        const isSelected = selectedItem.includes(value);

        if (isSelected) {
            setSelectedItem(selectedItem.filter((item: any) => item !== value));
        } else {
            setSelectedItem([...selectedItem, value as string]);
        }
    };

    const handleSubmit = () => {
        const selectedItems = selectData.filter(data => selectedItem.some((si) => si === data.key));

        for(const item of selectedItems) {
            mixpanel.track('challenge_selected', {
                'version': 1.0,
                'title': item.title
              })
        }

        navigate('/personal-brand-step1')
    }

    return (
        <VideoCreationChallengesView DATA={selectData} handleSelect={handleMultiSelect} handleSubmit={handleSubmit} selectedItem={selectedItem}/>
    );
};

export default VideoCreationChallenges;
