import { EVENTS } from '../../utils/constants';

/**
 * communication happens with postmessage interface
 * stripe is opened in the spawned window (popup)
 * stripe should redirect to this page with ?success=true on success
 * all other success flag values means, that payment failed
 */

function PaymentCallback() {
  const query = new URLSearchParams(window.location.search);

  const isSuccess = query.get('success') === 'true';

  window.opener.postMessage(isSuccess ? EVENTS.PAYMENT_SUCCESS_EVENT : EVENTS.PAYMENT_FAILED_EVENT, '*');

  return (<></>);
}

export default PaymentCallback;
