import './PaywallOfferDeclinePopup.scss'
import Icon from "../../Icon/Icon";
import { useNavigate } from 'react-router-dom';

interface IPaywallOfferDeclinePopup {
  isOpened: boolean;
  setIsOpened(isOpen: boolean): void;
}

function PaywallOfferDeclinePopup(props: IPaywallOfferDeclinePopup) {
  const navigate = useNavigate();

  return (
    <div className={['paywall-offer-decline-popup', props.isOpened ? 'open': 'closed'].join(' ')}>
      <div className="paywall-offer-decline-popup__content">
        {/* <button
          className='paywall-offer-decline-popup__content__cross text-gray'
          onClick={() => props.setIsOpened(false)}
        >
          &times;
        </button> */}

        <div className="paywall-offer-decline-popup__content__files">
          <div className='paywall-offer-decline-popup__content__files__inner'>
            <Icon name="sad-face" />
          </div>
        </div>        

        <h1 className="paywall-offer-decline-popup__content__about-offer-miss">
          You're about to miss out
          <br />
          on our welcome offer!
        </h1>

        <div className='paywall-offer-decline-popup__content__is-sure-to-proceed'>
          Are you sure you want to proceed?
        </div>

        <div className='paywall-offer-decline-popup__content__footer'>
          <button
            className='paywall-offer-decline-popup__content__footer__sure'
            onClick={() => navigate('/download-app')}
          >
            Yes, I'm sure
          </button>

          <button
            className='paywall-offer-decline-popup__content__footer__give-offer'
            onClick={() => props.setIsOpened(false)}
          >
            No, give me the offer
          </button>
      </div>
      </div>
    </div>
  );
}

export default PaywallOfferDeclinePopup;
