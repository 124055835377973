import './Auth.scss';
import { useEffect} from 'react';

import GoBack from "../../components/UI/Buttons/GoBack/GoBack";
import DarkShinyButton from '../../components/UI/Buttons/DarkShinyButton/DarkShinyButton';
import { useNavigate } from 'react-router-dom';
import AppleIDClient from '../../sdk-clients/appleid';
import TermsPrivacySettings from '../../components/Agreements/TermsPrivacySettings/TermsPrivacySettings';
import mixpanel from 'mixpanel-browser';
import googleCodeOAuth2service from '../../services/google-code-oauth-2';

const Auth = () => {
    const navigate = useNavigate();
    useEffect(() => { 
        mixpanel.track('auth_page')
       }, []);

    async function onLoginGoogleClick() {
        mixpanel.track('auth_selected', {
            'version': 1.0,
            'title': 'google',
        });

        const code = await googleCodeOAuth2service.signInPopup({ scope: ['openid', 'profile', 'email'] });

        navigate(`/auth/google/callback?code=${encodeURIComponent(code)}`);
    }

    async function onLoginAppleClick() {
        // todo: apple access denied error
        mixpanel.track('auth_selected', {
            'version': 1.0,
            'title': 'apple'
          })

        const data = await AppleIDClient.auth.signIn();

        navigate(`/auth/apple/callback?code=${encodeURIComponent(data.authorization.code)}`);
    }

    async function onMailClick() {
        // todo: apple access denied error
        mixpanel.track('auth_selected', {
            'version': 1.0,
            'title': 'mail'
          })
        navigate('/auth/plain/step-1')
    }

    return (
        <div className='page auth-page fade-in'>
            <header className='auth-page__header'>
                <GoBack />
                <a href="/"><img className='logo' src="./images/logo.svg" alt="Logo"/></a>
                <img src="./images/step/step-9.svg" />
            </header>
            <main className='auth-page__main'>
                <div className='auth-page__main__text'>
                    <h3 className='text-align-center'>
                        We are almost ready
                    </h3>
                    <h2 className='text-align-center'>
                        Let’s create your 
                        <br />
                        brand’s strategy
                    </h2>
                </div>

                <div className='auth-page__main__login-options'>
                    <DarkShinyButton
                        onClick={onLoginGoogleClick}
                        icon='google'
                        text='Continue with Google'
                    />
                    <DarkShinyButton
                        onClick={onLoginAppleClick}
                        icon='apple-corp-white'
                        text='Continue with Apple'
                    />
                    <DarkShinyButton
                        onClick={onMailClick}
                        icon='message'
                        text='Continue with Email'
                    />
                </div>
            </main>

            <footer className='auth-page__footer'>
                <TermsPrivacySettings />
            </footer>
        </div>
    );
};

export default Auth;
