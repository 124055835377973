import './DarkShinyButton.scss';

import Icon from "../../Icon/Icon";

export interface IDarkShinyButton {
  icon?: string;
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function DarkShinyButton(props: IDarkShinyButton) {
  return (
    <button className='dark-shiny-button' onClick={props.onClick}>
      { props.icon ? <Icon name={props.icon} />: <span></span> }
      <span className='text-white'>{props.text}</span>
      <span></span>
    </button>
  );
}

export default DarkShinyButton;
