import { ReactElement } from 'react';
import './PackagePropositions.scss';
import Proposition, { IProposition } from './Proposition/Proposition';

export interface IPackagePropositions {
  title?: string;
  customTitle?: ReactElement;
  propositions: IProposition[];
}

function PackagePropositions(props: IPackagePropositions) {
  return (
    <div className='ui-package-propositions'>
      {
        props.customTitle ? props.customTitle : props.title
      }
      <div className="ui-package-propositions__body">
        {
          props.propositions.map((proposition, index) => {
            const isNotLastProposition = index !== props.propositions.length -1;

            return (
              <div className='ui-package-propositions__body__proposition' key={index}>
                <Proposition { ...proposition } />
                { isNotLastProposition ?
                  <hr className='ui-package-propositions__body__proposition__line' />:
                  <></> }
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

export default PackagePropositions;
