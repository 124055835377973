import React from 'react';
import ChoiceButton from "../Buttons/ChoiceButton/ChoiceButton"
import './ListWithSelects.scss';


export type ListWithSelectsButtonData = {
    key: string;
    title: string;
    subtitle?: string;
    emoji?: string;
    titleStyle?: React.CSSProperties;
    subtitleStyle?: React.CSSProperties;
    emojiStyle?: React.CSSProperties;
};

interface ListWithSelectsProps {
    data: ListWithSelectsButtonData[];
    selectedItem: string | null | string[];
    callback: (value: string) => void;
    multiSelect?: boolean | undefined;
    styleContainer?: React.CSSProperties;
    buttonStyle? :React.CSSProperties;
}

const ListWithSelects: React.FC<ListWithSelectsProps> = (props) => {
    const delay = 0.1;

    return (
        <div className='ListWithSelects' style={props.styleContainer}>
            {props.data.map((item, index) => (
                <div
                    key={index}
                    className='fade-in'
                    style={{animationDelay: `${index * delay}s`}}>
                    <ChoiceButton
                        buttonStyle={props.buttonStyle}
                        key={item.key}
                        callback={() => props.callback(item.key)}
                        isSelected={props.selectedItem?.includes(item.key)}
                        title={item.title}
                        subtitle={ item.subtitle }
                        emoji={item.emoji}
                        titleStyle={item.titleStyle}
                        subtitleStyle={item.subtitleStyle}
                        emojiStyle={item.emojiStyle}
                    />
                </div>
            ))}
        </div>
    );
};

export default ListWithSelects;
