import './Timer.scss'

import { useEffect, useMemo, useState } from 'react';
import IntToStringFormatter from '../../../utils/StringFormatter';

interface ITimer {
  /**
   * @prop {number} delay
   * integer in miliseconds
   */
  delay: number;
}

/**
 * returns array in format [minutes, seconds]
 */
function milisecondsToMinutesAndSeconds(ms: number): [number, number] {
  const msToSec = ms / 1000;
  const seconds = msToSec % 60;
  const minutes = (msToSec - seconds) / 60;

  return [minutes, seconds];
}

async function reduceSeconds(seconds: number, setter: React.Dispatch<React.SetStateAction<number>>): Promise<void> {
  if (seconds === 0) return;

  const remain = seconds - 1;

  setter(remain);

  setTimeout(() => reduceSeconds(remain, setter), 1000);
}

function Timer(props: ITimer) {
  const [delay, setDelay] = useState(props.delay / 1000);

  useEffect(() => { reduceSeconds(delay, setDelay) }, []);

  const [minutes, seconds] = useMemo(() => milisecondsToMinutesAndSeconds(delay * 1000), [delay]);

  return (
    <div className='ui-timer'>
      <div className='ui-timer__minutes'>{IntToStringFormatter.atLeast2Chars(minutes)}</div>
      :
      <div className='ui-timer__seconds'>{IntToStringFormatter.atLeast2Chars(seconds)}</div>
    </div>
  );
}

export default Timer;
