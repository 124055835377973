type PxSpace = `${number}px`;

export interface IWindowSpawnerFeatures {
  width?: PxSpace;
  height?: PxSpace;
  popup?: true;
  resizable?: boolean;
  location?: boolean;
  menubar?: boolean;
  modal?: boolean;
  toolbar?: boolean;
  directories?: boolean;
  status?: boolean;
  scrollbars?: boolean;
  copyhistory?: boolean;
}

export type WindowSpawnerOnMessage = (data: any) => any; 

/**
 * avoid using this class insides an async function
 * it would be blocked on Safari otherwise
 * 
 * note: .open works only once per user event
 */
class WindowSpawner {
  readonly name: string;
  window: Window | null = null;

  private readonly uri: URL;
  private readonly features: IWindowSpawnerFeatures;
  private readonly featuresString: string;
  
  constructor(name: string, uri: URL, features: IWindowSpawnerFeatures) {
    this.name = name;
    this.uri = uri;
    this.features = features;
    this.featuresString = Object.entries(this.features).map(([feature, value]) => `${feature}=${value === false ? 'no': value}`).join(',');
  }

  open(): void {
    this.window = window.open(this.uri, this.name, this.featuresString);
  }

  close(): void {
    this.window?.close();
  }

  /**
   * @param once should cb trigger only once flag
   */
  addMessageListener(cb: WindowSpawnerOnMessage, once: boolean = false) {
    const onMessage = (event: MessageEvent) => {
      if (event.source === this.window) {
        cb(event.data);

        if (once) window.removeEventListener('message', onMessage);
      };
    }

    window.addEventListener('message', onMessage);
  }
}

export default WindowSpawner;
