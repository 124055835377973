import {useState, useEffect} from 'react';
import TimeInvestmentView from "./TimeInvestmentView";
import {useNavigate} from "react-router-dom";
import {ListWithSelectsButtonData} from "../../../components/UI/ListWithSelect/ListWithSelect";
import mixpanel from 'mixpanel-browser';

const TimeInvestment = () => {
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState< string>('');
    useEffect(() => { 
        mixpanel.track('time_investment_page')
       }, [])


    const selectData: ListWithSelectsButtonData[] = [
        {
            key: '1',
            title: 'Low',
            subtitle: 'Less than 30 minutes',
            emoji: '⏱',
            emojiStyle: {marginRight: 10},
            subtitleStyle: {fontSize: 12, letterSpacing: -0.28, lineHeight: '133%'}
        },
        {
            key: '2',
            title: 'Can spend some time',
            subtitle: 'Between 30 minutes to 1 hour',
            emoji: '⌛',
            emojiStyle: {marginRight: 10},
            subtitleStyle: {fontSize: 12, letterSpacing: -0.28, lineHeight: '133%'}
        },
        {
            key: '3',
            title: 'I can be flexible',
            subtitle: 'More than 1 hour',
            emoji: '🕰',
            emojiStyle: {marginRight: 10},
            subtitleStyle: {fontSize: 12, letterSpacing: -0.28, lineHeight: '133%'}
        },
    ];

    const handleSelect = (value: string | any) => {
        setSelectedItem( value)
    };

    const handleSubmit = () => {
        mixpanel.track('time_investment_selected', {
            'version': 1.0,
            'title': selectData.find(data => data.key === selectedItem)
          })
        navigate('/in-front-camera-comfort')
    }

    return (
        <TimeInvestmentView DATA={selectData} handleSelect={handleSelect} handleSubmit={handleSubmit} selectedItem={selectedItem}/>
    );
};

export default TimeInvestment;
