import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './redux/store/Store';
import App from './components/App/App';

import MetaPixel from './integrations/meta-pixel';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <HelmetProvider>
                <App/>
                <MetaPixel />
            </HelmetProvider>
        </Provider>
    </React.StrictMode>
);
