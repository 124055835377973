import './Paywall.scss';
import Cross from '../../components/UI/Buttons/Cross/Cross';
import PackagePropositions, { IPackagePropositions } from '../../components/UI/PackagePropositions/PackagePropositions';
import Review, { IReview } from './Review/Review';
import SpecialOffer from '../../components/UI/SpecialOffer/SpecialOffer';
import { useEffect, useRef, useState } from 'react';
import PaywallOfferDeclinePopup from '../../components/UI/Popups/PaywallOfferDeclinePopup/PaywallOfferDeclinePopup';
import constants, { EVENTS } from '../../utils/constants';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';
import WindowSpawner from '../../utils/WindowSpawner';

interface ICreateStripeCustomer {
    email: string;
    metadata: {
        customer_user_id: string;
    };
}

interface IStripeCustomer {
    id: string;
}

interface ILineItem {
    price: string;
    quantity: number;
}

interface Discount {
    coupon?: string;
    promotion_code?: string;
}

interface ICreatePaymentLink {
    stripeCustomer: { id: string };
    mode?: 'payment' | 'setup' | 'subscription';
    uiMode?: 'embedded' | 'hosted';
    lineItems: ILineItem[];
    metadata: Record<string, any>;
    urls: { success: string; cancel: string };
    discounts?: Discount[];
}

async function createStripeCustomer(options: ICreateStripeCustomer): Promise<IStripeCustomer> {
    const response = await fetch(`${constants.WOIS_ONBOARDING_ORIGIN}/create-stripe-customer`, {
        body: JSON.stringify(options),
        method: 'POST',
    });

    const customer: IStripeCustomer = await response.json();

    return customer;
}

async function createPaymentLink(options: ICreatePaymentLink): Promise<string> {
    const response = await fetch(`${constants.WOIS_ONBOARDING_ORIGIN}/create-payment-link`, {
        body: JSON.stringify(options),
        method: 'POST',
    });

    const json = await response.json();

    return json.link;
}

const PaywallView = () => {
    const navigate = useNavigate();

    const [isCrossVisible, setCrossVisibility] = useState(false);
    const [IsPaywallOfferDeclinePopupOpened, setIsPaywallOfferDeclinePopupOpened] = useState(false);

    const [stripePaymentLink, setStripePaymentLink] = useState<URL>();

    useEffect(() => {
        const woisUserId = localStorage.getItem('userId')!;
        const email = localStorage.getItem('email')!;

        createStripeCustomer({
            email,
            metadata: { customer_user_id: woisUserId },
        })
            .then(stripeCustomer => {
                const body: ICreatePaymentLink = {
                    stripeCustomer: { id: stripeCustomer.id },
                    mode: 'subscription',
                    uiMode: 'hosted',
                    lineItems: [
                        {
                            price: constants.SUBSCRIPTION_STRIPE_PRICE_ID,
                            quantity: 1,
                        },
                    ],
                    discounts: [
                        { promotion_code: constants.WOIS2024_PROMO_ID }
                    ],
                    metadata: {
                        customer_user_id: woisUserId,
                    },
                    urls: {
                        success: window.location.origin.concat('/payment-callback?success=true'),
                        cancel: window.location.origin.concat('/payment-callback'),
                    },
                };

                return createPaymentLink(body);
            })
            .then(link => setStripePaymentLink(new URL(link)));
    }, []);

    const joinOver6000Ref = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    setCrossVisibility(true);
                    observer.disconnect();
                }
            },
            { threshold: 1 }
        );

        observer.observe(joinOver6000Ref.current!);

        return () => observer.disconnect();
    }, []);

    const handleCrossButton = () => {
        setIsPaywallOfferDeclinePopupOpened(true);
    }

    function onSpecialOfferClaim(paymentLink: URL) {
        mixpanel.track('special_offer_claim');

        const paymentWindow = new WindowSpawner('payment-window', paymentLink, {
            popup: true,
            width: '700px',
            height: '700px',
            resizable: false,
            location: false,
            menubar: false,
        });

        paymentWindow.open();

        paymentWindow.addMessageListener(
            (data) => {
                paymentWindow.close();

                if (data === EVENTS.PAYMENT_SUCCESS_EVENT) navigate('/welcome-aboard');
            },
            true,
        );
    }

    const exclusivePropositions: IPackagePropositions['propositions'] = [
        {
            icon: 'lamp',
            title: '2000+ ideas for your videos ',
        },
        {
            icon: 'ribbon-plus',
            title: '30+ AI-edited engaging videos',
        },
        {
            icon: 'flag',
            title: 'Multi-language Captions',
        },
        {
            icon: 'voice',
            title: 'Dubbing to 20+ Languages',
        },
        {
            icon: 'smartphone',
            title: 'Teleprompter for smooth recording',
        },
        {
            icon: 'mac-someone-docs',
            title: 'Your personal website',
        },
    ];

    const reviews: IReview[] = [
        {
            comment: 'Wois is like Linkedin meets Tiktok… Easy to use and lots of smart talk to have',
            stars: 5,
            reviewer: {
                avatarUrl: './images/peoples/cory-warfield.png',
                name: 'Cory Warfield',
                label: 'LinkedIn Top Voice',
            },
        },
        {
            comment: 'Just by talking about startup culture and entrepreneurship on Wois, I found an investor for my startup.',
            stars: 5,
            reviewer: {
                avatarUrl: './images/peoples/tonia.png',
                name: 'Tonia Samsonova',
                label: 'Founder of Exactly.ai',
            },
        },
        {
            comment: 'Easiest place to build a network and my personal brand just by being myself.',
            stars: 5,
            reviewer: {
                avatarUrl: './images/peoples/shahab.png',
                name: 'Shahab Anbarjafari',
                label: 'Professor, Chief Data Scientist at PwC',
            },
        },
    ];

    return (
        <div className='page paywall-page fade-in'>
            <div className={['paywall-page__cross', isCrossVisible ? '' : 'display-none' ].join(' ')}>
                <Cross onClick={handleCrossButton} />
            </div>

            <header className='paywall-page__header'>
                <div></div>
                <a href="/"><img className='logo' src="./images/logo.svg" alt="Logo"/></a>
                <div></div>
            </header>

            <main className='paywall-page__main'>
                <h2>
                    A complete toolkit
                    <br />
                    for your
                    <br />
                    <span className='text-dase'>Personal Brand!</span>
                </h2>
                <br />
                <br />
                <img src="/images/peoples/hanna-philips.png" alt="wois exists compared to does not chart" />

                <div className='paywall-page__main__exclusive-proposition'>
                    <PackagePropositions
                        customTitle={
                            <h2 className='paywall-page__main__exclusive-proposition__title'>
                                Secure your
                                &nbsp;
                                <span className='text-dase'>
                                    exclusive package
                                </span>
                                &nbsp;
                                <br />
                                now and receive:
                            </h2>
                        }
                        propositions={exclusivePropositions}
                    />
                </div>

                <br />

                <h2 className='text-white'>
                    Personal branding experts
                    <br />
                    trust us!
                </h2>

                <br />
                <br />

                <div className="paywall-page__main__reviews">
                    {reviews.map((review, i) => <Review {...review} key={i} />)}
                </div>

                <h2>
                    Build your personal brand
                    <br />
                    with just your phone
                </h2>

                <br />
                <br />

                <SpecialOffer
                    title='Limited Welcome Offer'
                    price={18}
                    currency='$'
                    discount={50}
                    delay={600}
                    isLoading={!stripePaymentLink}

                    onClaim={() => onSpecialOfferClaim(stripePaymentLink!)}
                />

                <h2 className='paywall-page__main__join-over-6000' ref={joinOver6000Ref}>
                    Join over 6,000 Personal Brand Builders!
                </h2>

                <br />

                <img
                    className="paywall-page__main__app-rating"
                    src="/images/app-rating.svg"
                    alt="app rating 4.7 points"
                />

                <br />

                <div className='paywall-page__main__awards'>
                    <div className='paywall-page__main__awards__top'>
                        <img src="/images/awards/award-3.svg" />
                        <img src="/images/awards/award-4.svg" />
                    </div>
                    <div className='paywall-page__main__awards__bottom'>
                        <img src="/images/awards/award-1.svg" />
                        <img src="/images/awards/award-2.svg" />
                    </div>
                </div>
            </main>
            
            <footer className='paywall-page__footer'>
                <div className='text-gray'>© 2024 Wois app</div>
                <div className='paywall-page__footer__terms'>
                    <a href='https://wois.io/terms' target='_blank'>Terms and conditions</a>
                </div>
                <div className='text-gray'>
                    <a href='https://wois.io/privacy' target='_blank'>Privacy Policy</a>
                </div>
            </footer>

            <PaywallOfferDeclinePopup
                isOpened={IsPaywallOfferDeclinePopupOpened}
                setIsOpened={setIsPaywallOfferDeclinePopupOpened}
            />
        </div>
    );
};

export default PaywallView;
