import AppleIDClient from '../lib/apple-auth';
import appleProperties from '../apple-properties.json';

AppleIDClient.auth.init({
  clientId : appleProperties.identifier,
  scope : 'email name openid',
  redirectURI: window.location.origin.concat('/auth/apple/callback'),
  usePopup: true,
});

export default AppleIDClient;
