import {configureStore} from '@reduxjs/toolkit';
import AppReducer, {} from '../AppSlice';
import UserSlice from '../UserSlice';

export const store = configureStore({
  reducer: {
    app: AppReducer,
    user: UserSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


