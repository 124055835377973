import googleProperties from '../google-properties.json';
import WindowSpawner from '../utils/WindowSpawner';

interface ISignInPopup {
  scope: string[];
  prompt?: string;
  accessType?: string;
  includeGrantedScopes?: boolean;
  enableGranularConsent?: boolean;
}

/**
 * @prop {String} clientId
 * depends on page of the app auth/google/callback/popup
 */
class GoogleCodeOAuth2 {
  private readonly clientId: string = googleProperties.web.client_id;

  /**
   * @returns code
   */
  signInPopup({
    scope,
    prompt = 'consent',
    accessType = 'offline',
    includeGrantedScopes = true,
    enableGranularConsent = true,
  }: ISignInPopup): Promise<string> {

    return new Promise((resolve, reject) => {
      const url = new URL('https://accounts.google.com/o/oauth2/v2/auth');

      url.searchParams.append('client_id', this.clientId);
      url.searchParams.append('redirect_uri', window.location.origin.concat('/auth/google/callback'));
      url.searchParams.append('response_type', 'code');
      url.searchParams.append('scope', scope.join(' '));
      url.searchParams.append('prompt', prompt);
      url.searchParams.append('access_type', accessType);
      url.searchParams.append('include_granted_scopes', String(includeGrantedScopes));
      url.searchParams.append('enable_granular_consent', String(enableGranularConsent));

      const oauth2Window = new WindowSpawner('google-oauth2', url, {
        popup: true,
        width: '700px',
        height: '700px',
        resizable: false,
        location: false,
        menubar: false,
      });

      oauth2Window.open();

      oauth2Window.addMessageListener(
        (data) => {
            const code = data.code;
            const isSuccess = !!code;

            if (isSuccess) resolve(code);
            else reject(new Error(data.error ?? 'unknown error'));

            oauth2Window!.close();
        },
        true,
      );
    });
  }
}

const googleCodeOAuth2service = new GoogleCodeOAuth2();

export default googleCodeOAuth2service;
