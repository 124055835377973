import {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {ListWithSelectsButtonData} from "../../../components/UI/ListWithSelect/ListWithSelect";
import InFrontCameraView from "./InFrontCameraView";
import mixpanel from 'mixpanel-browser';

const InFrontCamera = () => {
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState< string>('');
    useEffect(() => { 
        mixpanel.track('in_front_of_camera_page')
       }, [])

    const selectData:ListWithSelectsButtonData[] = [
        {
            key: '1',
            title: `Yes, I love it!`,
            emoji: '🤩',
            emojiStyle: { lineHeight: '.5rem' },
        },
        {
            key: '2',
            title: `I'm a bit shy, but I can manage`,
            emoji: '😏',
            emojiStyle: { lineHeight: '.5rem' },
        },
        {
            key: '3',
            title: `No, it makes me uncomfortable`,
            emoji: '🫣',
            emojiStyle: { lineHeight: '.5rem' },
        },
    ];



    const handleSelect = (value: string | any) => {
        setSelectedItem( value)
    };

    const handleSubmit = () => {
        
        mixpanel.track('in_front_camera_selected', {
            'version': 1.0,
            'title': selectData.find(data => data.key === selectedItem)
          })
        navigate('/video-effectiveness')
    }

    return (
        <InFrontCameraView DATA={selectData} handleSelect={handleSelect} handleSubmit={handleSubmit} selectedItem={selectedItem}/>
    );
};

export default InFrontCamera;
