import { useEffect } from 'react';
import './WelcomeAboard.scss';
import GoBack from "../../components/UI/Buttons/GoBack/GoBack";
import GetAppMultiOsButton from '../../components/UI/Buttons/AppDownloadButton/GetAppMultiOsButton';
import mixpanel from 'mixpanel-browser';

const WelcomeAboard = () => {

    useEffect(() => { 
        mixpanel.track('welcome_aboard_page')
       }, [])
  
    return (
        <div className='page welcome-aboard-page fade-in'>
            <header className='welcome-aboard-page__header'>
                <GoBack/>
                <a href="/"><img className='logo' src="./images/logo.svg" alt="Logo"/></a>
                <div></div>
            </header>
            <main className='welcome-aboard-page__main'>
                <div className='welcome-aboard-page__main__text'>
                    <h2 className='text-align-center'>
                        Welcome aboard!
                    </h2>
                    <h3 className='text-align-center'>
                        Now, let's dive right into
                        <br/>
                        crafting your personal brand!
                    </h3>
                </div>

                <div className='welcome-aboard-page__main__app-store-download-btn'>
                    <GetAppMultiOsButton />
                </div>
            </main>
        </div>
    );
};

export default WelcomeAboard;
